window.onload = function () {

  /* show / hide main nav */

  const btnnav = document.querySelector('.js-togglemenu');
  const mobilenav = document.querySelector('.js-navmain');
  const logo = document.querySelector('.logo');
  const header = document.querySelector('header');

  // Toggle menu to visible
  btnnav.addEventListener("click", () => {
    btnnav.classList.toggle('active');
    mobilenav.classList.toggle('is-open');
    logo.classList.toggle('dark');
    header.classList.toggle('zindex');
  });

  // scroll up
  const scrollUpBtn = document.querySelector(".js-scroll-top");

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  scrollUpBtn.addEventListener("click", () => {
    scrollToTop();
  });

  // scroll down
  const scrollDownBtn = document.querySelector(".js-scroll-down");
  var anchor = document.querySelector('#scrollContent');

  scrollDownBtn.addEventListener("click", () => {
    // scrollToContent();
    anchor.scrollIntoView({ behavior: "smooth" });
  });

  // toggle download description
  var download = document.querySelectorAll(".js-toggle-download");

  download.forEach(function (show) {
    show.addEventListener("click", () => {
      show.classList.toggle('active');
      var description = show.parentElement.nextElementSibling;
      description.classList.toggle('show');
    });
  });
};

/* sticky header */
window.onscroll = function () { stickyNav() };

var navbar = document.querySelector('body');
var sticky = navbar.offsetTop + 50;
var html = document.querySelector('header');

function stickyNav() {
  if (window.pageYOffset >= sticky) {
    html.classList.add("sticky")
  } else {
    html.classList.remove("sticky");
  }
}
