/**
    Author:  click solutions GmbH
             Marco Both
    Date:    Ver1.0 - 31.05.2022
    Project: Perspektive Wismar
 */
import accordion from './module/accordion'
import swiper from './module/swiper'
import custom from './module/custom'

import '../scss/styles.scss';
